<template>
  <div class="text-center" style="height: calc(100vh - 25vh)">
    <div class="flex-container">
      <div class="row">
        <div class="flex-item">
          <img src="/img/logo/logo.png" width="620px">
        </div>
        <div class="flex-item" style="text-align: left">
          <h6 class="text-dark">USER: {{ $xBundle['CURRENT_USER_PROFILE'].display_name }}</h6>
        </div>
      </div>
    </div>

    <div class="mt-5"  v-if="isUserRoot">
      <el-row :gutter="6" style="display: flex; vertical-align: top;flex-wrap: wrap;">
        <dashComp v-for="(comp, key) in dashCompsProps" :key="key" :span="spanSize" :data="comp"></dashComp>
      </el-row>
    </div>
  </div>
</template>

<script>
  import fieldController from 'devegram-vue-data-collecter-eui/src/mixins/fieldController';
  export default {
    components: {
      dashComp: () => import('./DashComp')
    },
    mixins: [ fieldController],
    mounted() {

    },
    data () {
      return {
        dashCompsProps: [
          {
            title: 'Users',
            icon: 'el-icon-user',
            apiStore: 'App-User-api',
            action: () => { $router.push({name: 'module->user->list->page', props: {listPage: 1}})},
            actionLabel: 'Open'
          },
        ],
        spanSize: 6,
        isUserRoot: $store.getters['App-User-state/isUserRoot'],
        control: this,
        dataModel: {
          location: 'fff'
        }
      }
    }

  }
</script>

<style scoped>
html, body {
  height: 100%;
}
body {
  margin: 0;
}
.flex-container {
  height: 100%;
  padding: 0;
  margin: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}
.row {
  width: auto;
  border: 1px solid #c0c0c0;
  padding: 100px;
}
.flex-item {
  padding: 5px;
  margin: 10px;
  line-height: 20px;
  color: white;
  font-weight: bold;
  font-size: 2em;
  text-align: center;
}
</style>
